import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { AuthService } from 'src/lib/auth/auth.service';
import { ICarGoods } from 'src/lib/repository/car-goods/car-goods.model';
import { IFlights } from 'src/lib/repository/flights/flights.model';
import { IHotelGoods } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';
import { IPackagegoods } from 'src/lib/repository/packagegoods/packagegoods.model';
import { IUserInfo } from 'src/lib/repository/user-info/user-info.model';
import { Utils } from '../../lib/utils';

// TODO: 예약 관련 서비스 통합
@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private authService: AuthService) {}

  // FIXME: 타입 정의, 해당 기능 관리 주체 검토
  // FIXME: 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
  /**
   * @deprecated 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
   */
  getBookingPostParams(params: any): any {
    const userInfo = this.authService.account?.userInfo;
    const { tourNumber, packagegoods, startDate, returnDate } = params;
    const { brand } = packagegoods;
    const { customerClass } = userInfo;
    const nights = dayjs(returnDate).diff(dayjs(startDate), 'day');
    const twnNumber = Math.floor(tourNumber / 2);
    const sglNumber = Math.ceil(tourNumber % 2);

    let bookingTitleKo = `${userInfo?.userNm}님 일행 ${nights}박`;

    let bookingTitleJa = `${
      userInfo?.familyName || userInfo?.firstName
        ? `${userInfo?.familyName} ${userInfo?.firstName}`
        : userInfo?.userNm
    }様ご一行${nights}泊`;

    let bookingTitleEn = `${
      userInfo?.familyName || userInfo?.firstName
        ? `${userInfo?.firstName} ${userInfo?.familyName}`
        : userInfo?.userNm
    }'s group for ${nights} nights`;

    if (nights === 0) {
      bookingTitleKo = `${userInfo?.userNm}님 일행 ${1}일`;

      bookingTitleJa = `${
        userInfo?.familyName || userInfo?.firstName
          ? `${userInfo?.familyName} ${userInfo?.firstName}`
          : userInfo?.userNm
      }様ご一行${1}日`;

      bookingTitleEn = `${
        userInfo?.familyName || userInfo?.firstName
          ? `${userInfo?.firstName} ${userInfo?.familyName}`
          : userInfo?.userNm
      }'s group for ${1} day`;
    }

    const newParams = {
      ...params,
      bookingMember: this.makeMembers(params.tourNumber),
      tourTerm: `${nights}N${+nights + 1}D`,
      tradeCode: '온라인',
      sglNumber,
      twnNumber,
      brand,
      customerClass,
      representative: userInfo,
      bookingTitle: bookingTitleKo,
      bookingTitleKo,
      bookingTitleJa,
      bookingTitleEn,
      // TODO: 에이전트 찾는 방식 검토
      agentId: 2,
      agentName: '온라인',
      packagegoods: {
        id: packagegoods.id,
        createBookingScheduleFlg: packagegoods.createBookingScheduleFlg,
      },
      delng: { userInfo },
    };

    return newParams;
  }

  // FIXME: 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
  /**
   * @deprecated 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
   */
  getBookingBody(
    startDate: string,
    returnDate: string,
    tourNumber: number,
    packagegoods: IPackagegoods,
    userInfo: IUserInfo,
    departingFlight: IFlights,
    returnFlight: IFlights,
    hotelGoods: IHotelGoods,
    sglNumber: number = 0,
    twnNumber: number = 0,
    trpNumber: number = 0,
    quaNumber: number = 0,
    etcNumber: number = 0,
    hotelGoodsList?: IHotelGoods[],
  ): any {
    const { brandId } = packagegoods;
    const { customerClass } = userInfo;
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(startDate).getTime();
    const secondDate = new Date(returnDate).getTime();
    const nights = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    const defaultRoomType = this.getRoomTypeNumber(tourNumber, hotelGoods);

    let newSglNumber = sglNumber;
    let newTwnNumber = twnNumber;
    let newTrpNumber = trpNumber;
    let newQuaNumber = quaNumber;

    if (!(sglNumber || twnNumber || trpNumber || quaNumber)) {
      newQuaNumber = 0;
      newTrpNumber = 0;
      newTwnNumber = Math.floor(tourNumber / 2);
      newSglNumber = Math.ceil(tourNumber % 2);

      if (defaultRoomType) {
        newQuaNumber = 0;
        newTrpNumber = 0;
        newTwnNumber = 0;
        newSglNumber = 0;

        if (defaultRoomType.type === 'sglNumber') {
          newSglNumber = defaultRoomType.roomNumber;
        }

        if (defaultRoomType.type === 'twnNumber') {
          newTwnNumber = defaultRoomType.roomNumber;
        }

        if (defaultRoomType.type === 'trpNumber') {
          newTrpNumber = defaultRoomType.roomNumber;
        }

        if (defaultRoomType.type === 'qudNumber') {
          newQuaNumber = defaultRoomType.roomNumber;
        }
      }
    }

    const representativeNm =
      userInfo?.familyName || userInfo?.firstName
        ? `${userInfo?.familyName} ${userInfo?.firstName}`
        : userInfo?.userNm;

    const bookingTitle = [];
    const bookingTitleJa = [];
    const bookingTitleEn = [];

    bookingTitle.push(`${userInfo?.userNm}님 일행`);
    bookingTitleJa.push(`${representativeNm}様ご一行`);
    bookingTitleEn.push(`${representativeNm}'s group`);

    bookingTitle.push(`${nights}박`);
    bookingTitleJa.push(`${nights}泊`);
    bookingTitleEn.push(`for ${nights} nights`);

    if (packagegoods?.id) {
      const { title } = packagegoods;

      bookingTitle.push(`[${title}]`);
      bookingTitleJa.push(`[${title}]`);
      bookingTitleEn.push(`[${title}]`);
    }

    const hotelSoldCount =
      newSglNumber + newTwnNumber + newTrpNumber + newQuaNumber;

    const params = {
      startDate,
      returnDate,
      tourNumber,
      bookingStateCode: 'RECEIVE',
      bookingSubStateCode: '예약진행중',
      bookingMember: this.makeMembers(tourNumber),
      tourTerm: `${nights}N${+nights + 1}D`,
      tradeCode: '온라인',
      sglNumber: newSglNumber,
      twnNumber: newTwnNumber,
      trpNumber: newTrpNumber,
      quaNumber: newQuaNumber,
      etcNumber,
      brandId,
      customerClass,
      representative: userInfo,
      bookingTitle: bookingTitle.join(' '),
      bookingTitleKo: bookingTitle.join(' '),
      bookingTitleJa: bookingTitleJa.join(' '),
      bookingTitleEn: bookingTitleEn.join(' '),
      // TODO: 에이전트 찾는 방식 검토
      agentId: 2,
      agentName: '온라인',
      packagegoods: {
        id: packagegoods.id,
        createBookingScheduleFlg: packagegoods.createBookingScheduleFlg,
      },
      selectedGoodsId: hotelGoods?.id,
      selectedGoodsName: !packagegoods.createBookingScheduleFlg
        ? null
        : hotelGoods?.goodsNm,
      selectedMrhstId: hotelGoods?.mrhst?.id,
      hotelSoldCount,
      departingFlight,
      returnFlight,
      // 상품 목록
      hotelGoodsListJson: Utils.getStringifiedJson(hotelGoodsList, '[]'),
    };
    return params;
  }

  getHotelBookingBody(
    startDate: string,
    returnDate: string,
    tourNumber: number,
    userInfo: IUserInfo,
    mrhst: IMrhst,
    hotelGoods: IHotelGoods,
  ): any {
    const { customerClass } = userInfo;
    const { brandId } = mrhst;
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(startDate).getTime();
    const secondDate = new Date(returnDate).getTime();
    const nights = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    const defaultRoomType = this.getRoomTypeNumber(tourNumber, hotelGoods);

    let quaNumber = 0;
    let trpNumber = 0;
    let twnNumber = Math.floor(tourNumber / 2);
    let sglNumber = Math.ceil(tourNumber % 2);

    if (defaultRoomType) {
      quaNumber = 0;
      trpNumber = 0;
      twnNumber = 0;
      sglNumber = 0;

      if (defaultRoomType.type === 'sglNumber') {
        sglNumber = defaultRoomType.roomNumber;
      }

      if (defaultRoomType.type === 'twnNumber') {
        twnNumber = defaultRoomType.roomNumber;
      }

      if (defaultRoomType.type === 'trpNumber') {
        trpNumber = defaultRoomType.roomNumber;
      }

      if (defaultRoomType.type === 'qudNumber') {
        quaNumber = defaultRoomType.roomNumber;
      }
    }

    const representativeNm =
      userInfo?.familyName || userInfo?.firstName
        ? `${userInfo?.familyName} ${userInfo?.firstName}`
        : userInfo?.userNm;

    const bookingTitle = [];
    const bookingTitleJa = [];
    const bookingTitleEn = [];

    bookingTitle.push(`${userInfo?.userNm}님 일행`);
    bookingTitleJa.push(`${representativeNm}様ご一行`);
    bookingTitleEn.push(`${representativeNm}'s group`);

    bookingTitle.push(`${nights}박`);
    bookingTitleJa.push(`${nights}泊`);
    bookingTitleEn.push(`for ${nights} nights`);

    if (mrhst?.id) {
      const { mrhstNm } = mrhst;

      bookingTitle.push(`[${mrhstNm}]`);
      bookingTitleJa.push(`[${mrhstNm}]`);
      bookingTitleEn.push(`[${mrhstNm}]`);
    }

    const params = {
      startDate,
      returnDate,
      tourNumber,
      bookingStateCode: 'RECEIVE',
      bookingSubStateCode: '예약진행중',
      tourTerm: `${nights}N${+nights + 1}D`,
      tradeCode: '온라인',
      sglNumber,
      twnNumber,
      trpNumber,
      quaNumber,
      brandId,
      customerClass,
      representative: userInfo,
      bookingTitle: bookingTitle.join(' '),
      bookingTitleKo: bookingTitle.join(' '),
      bookingTitleJa: bookingTitleJa.join(' '),
      bookingTitleEn: bookingTitleEn.join(' '),
      // TODO: 에이전트 찾는 방식 검토
      agentId: 2,
      agentName: '온라인',
      selectedGoodsId: hotelGoods?.id,
      selectedGoodsName: hotelGoods?.goodsNm,
      selectedMrhstId: mrhst?.id,
      bookingType: 'HOTEL',
    };
    return params;
  }

  /**
   * 렌트카 예약 등록 요청 바디 획득
   */
  getCarBookingBody(
    startDate: string,
    returnDate: string,
    userInfo: IUserInfo,
    mrhst: IMrhst,
    carGoods: ICarGoods,
  ): any {
    const { customerClass } = userInfo;
    const { brandId } = mrhst;
    const nights = dayjs(returnDate).diff(startDate, 'day');

    const representativeNm =
      userInfo?.familyName || userInfo?.firstName
        ? `${userInfo?.familyName} ${userInfo?.firstName}`
        : userInfo?.userNm;

    const bookingTitle = [];
    const bookingTitleJa = [];
    const bookingTitleEn = [];

    bookingTitle.push(`${userInfo?.userNm}님 일행`);
    bookingTitleJa.push(`${representativeNm}様ご一行`);
    bookingTitleEn.push(`${representativeNm}'s group`);

    if (mrhst?.id) {
      const { goodsNm } = carGoods;

      bookingTitle.push(`[${goodsNm}]`);
      bookingTitleJa.push(`[${goodsNm}]`);
      bookingTitleEn.push(`[${goodsNm}]`);
    }

    const body = {
      startDate,
      returnDate,
      tourNumber: 1,
      bookingStateCode: 'RECEIVE',
      bookingSubStateCode: '예약진행중',
      tourTerm: `${nights}N${+nights + 1}D`,
      tradeCode: '온라인',
      sglNumber: 0,
      twnNumber: 0,
      trpNumber: 0,
      quaNumber: 0,
      brandId,
      customerClass,
      representative: userInfo,
      bookingTitle: bookingTitle.join(' '),
      bookingTitleJa: bookingTitleJa.join(' '),
      bookingTitleEn: bookingTitleEn.join(' '),
      // TODO: 에이전트 찾는 방식 검토
      agentId: 2,
      agentName: '온라인',
      selectedCarGoodsId: carGoods?.id,
      selectedMrhstId: mrhst?.id,
      bookingType: 'CAR',
    };

    return body;
  }

  // FIXME: 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
  /**
   * 기본 룸 타입 및 갯수 반환
   *
   * 없으면 null 반환
   *
   * @deprecated 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
   */
  private getRoomTypeNumber(
    tourNumber: number,
    hotelGoods: IHotelGoods,
  ): { type: string; roomNumber: number } | null {
    if (!hotelGoods) {
      return null;
    }

    const {
      roomTypeByTourNumberFlg,
      roomTypeSglFlg,
      roomTypeTwnFlg,
      roomTypeTrpFlg,
      roomTypeQudFlg,
      roomTypeEtcFlg,
      roomTypeEtcAvailMinPerson,
      roomTypeQudAvailMinPerson,
      roomTypeSglAvailMinPerson,
      roomTypeTrpAvailMinPerson,
      roomTypeTwnAvailMinPerson,
      roomTypeEtcAvailMaxPerson,
      roomTypeQudAvailMaxPerson,
      roomTypeSglAvailMaxPerson,
      roomTypeTrpAvailMaxPerson,
      roomTypeTwnAvailMaxPerson,
    } = hotelGoods;
    // 룸 타입별 사용 여부 체크 한 개수
    const positiveNumber =
      roomTypeSglFlg! +
      roomTypeTwnFlg! +
      roomTypeTrpFlg! +
      roomTypeQudFlg! +
      roomTypeEtcFlg!;

    if (
      // 룸 타입별 관리 하지 않거나
      !roomTypeByTourNumberFlg ||
      // 하나도 체크하지 않았다면
      positiveNumber === 0
    ) {
      // 종료
      return null;
    }

    // 1개보다 많으면
    if (positiveNumber > 1) {
      // 종료
      return null;
    }

    // 키 이름
    let keyName: string = '';
    // 기본 인원
    let defaultPerson: number = 0;
    // 최소 인원
    let availMinPerson: number = 0;
    // 최대 인원
    let availMaxPerson: number = 0;

    if (roomTypeSglFlg === 1) {
      defaultPerson = 1;
      availMinPerson = roomTypeSglAvailMinPerson || 1;
      availMaxPerson = roomTypeSglAvailMaxPerson || defaultPerson;
      keyName = 'sglNumber';
    } else if (roomTypeTwnFlg === 1) {
      defaultPerson = 2;
      availMinPerson = roomTypeTwnAvailMinPerson || 1;
      availMaxPerson = roomTypeTwnAvailMaxPerson || defaultPerson;
      keyName = 'twnNumber';
    } else if (roomTypeTrpFlg === 1) {
      defaultPerson = 3;
      availMinPerson = roomTypeTrpAvailMinPerson || 1;
      availMaxPerson = roomTypeTrpAvailMaxPerson || defaultPerson;
      keyName = 'trpNumber';
    } else if (roomTypeQudFlg === 1) {
      defaultPerson = 4;
      availMinPerson = roomTypeQudAvailMinPerson || 1;
      availMaxPerson = roomTypeQudAvailMaxPerson || defaultPerson;
      // quaNumber 아니므로 주의
      keyName = 'qudNumber';
    } else if (roomTypeEtcFlg === 1) {
      // 기본은 2인실로 판단
      defaultPerson = roomTypeEtcAvailMaxPerson || 2;
      availMinPerson = roomTypeEtcAvailMinPerson || 1;
      availMaxPerson = roomTypeEtcAvailMaxPerson || defaultPerson;
      keyName = 'etcNumber';
    }

    // 최소 최대가 0이 아니고 같다면
    if (availMinPerson === availMaxPerson && availMaxPerson !== 0) {
      // 인원수와 딱 맞아 떨어지면
      if (tourNumber % availMinPerson === 0) {
        // 객실 수 초기값 입력
        return { type: keyName, roomNumber: tourNumber / availMinPerson };
      }
    }

    // 최소 필요 객실
    const minRequiredRoom = Math.ceil(tourNumber / availMaxPerson);
    // 최대 필요 객실
    const maxRequiredRoom = Math.ceil(tourNumber / availMinPerson);
    // 최소 수용 인원
    const minCapacity = minRequiredRoom * availMinPerson;
    // 최대 수용 인원
    const maxCapacity = maxRequiredRoom * availMaxPerson;
    // 기본 필요 객실
    const defaultRequiredRoom = Math.ceil(tourNumber / defaultPerson);

    // 객실 인원수 제한에 부합하면
    if (tourNumber >= minCapacity && tourNumber <= maxCapacity) {
      // 객실 수 초기값 입력
      return { type: keyName, roomNumber: defaultRequiredRoom };
    }

    return null;
  }

  // FIXME: 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
  /**
   * 첫번째는 로그인 유저, 나머지는 무기명으로 입력
   * @deprecated 통신 서비스와 클라이언트에서 데이터를 다루는 서비스는 분리
   */
  makeMembers(length: number): { [key: string]: any }[] {
    const arr: ReturnType<BookingService['makeMembers']> = [];

    // for (let i = 0; i < length; i += 1) {
    //   arr.push({ userInfo: null! });
    // }

    arr[0] = {
      judgmentInput: 1,
      userInfo: this.authService.account?.userInfo,
    };

    return arr;
  }
}
