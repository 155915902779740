import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * 폼 컴포넌트 추상클래스
 */
@Directive()
export abstract class AbstractForm implements AfterViewInit {
  /**
   * 폼그룹
   */
  abstract formGroup: FormGroup;

  /**
   * 초기화시 스스로를 반환
   */
  @Output() init = new EventEmitter<this>();

  ngAfterViewInit(): void {
    this.init.emit(this);
  }

  /**
   * 폼그룹 값 반환
   */
  getValue(): any {
    return this.formGroup.getRawValue();
  }

  /**
   * 폼그룹 값 설정
   */
  setValue(value: any): void {
    this.formGroup.patchValue(value);
  }
}
